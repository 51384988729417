import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import SmartContainer from '../layout/SmartContainer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clear from '../assets/img/clear-5x7.png';

const News = (props: any) => {
    const {} = props;

    const init: any = [];
    const [newsLoaded, setNewsLoaded] = useState(false);
    const [articles, setArticles] = useState(init);

    useEffect(() => {
        (async () => {
            if (!newsLoaded) {
                const headers = { 'headers': { 'Authorization': 'A5O4l1iHcEqLvb3ilvdm/A==' } }
                let news: any = await axios.get('/api/v2/objects/data/News?size=12&i=0&t=&sort=desc&q=', headers);
                if (news && news.data && news.data.__a && news.data.__a.count > 0) {
                    news.data.r.sort((a:any, b:any) => {
                        let da:any = new Date(a.PublishDate),
                            db:any = new Date(b.PublishDate);
                        return db - da;
                    });
                    setArticles(news.data.r);
                }
                setNewsLoaded(true);
            }
        })();
    }, [newsLoaded])

    return (
        <div>
            <div id="splash-sub" className="small about">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">News & Events</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>

            <div className="news-div">
                <SmartContainer>
                    <div className="row">
                        {articles.length && newsLoaded ?
                            articles.map((art: any) =>
                                <div className="col-12 col-md-6 col-lg-4" key={art.__i.guid}>
                                    <div className="news-item">
                                        <Link to={art.UrlRedirect.length ? art.UrlRedirect : '/news/' + art.Slug}>
                                            <div className="news-image" style={{'backgroundImage':'url('+(art.Photo.length ? '/v2/objects/locker/'+art.Photo : 'https://via.placeholder.com/500x300.png?text=News+Image')+')'}}>
                                                <img src={clear} />
                                            </div>
                                            {/*<img src={art.Photo.length ? art.Photo : 'https://via.placeholder.com/500x300.png?text=News+Image'}/>*/}
                                            <b>{moment(art.PublishDate).format('MM.DD.YYYY')}</b><br/>
                                            <span>{art.Title}</span>
                                        </Link>
                                    </div>
                                </div>
                            )
                        : !articles.length && newsLoaded ?
                            <div className="col-12">
                                <div className="alert alert-warning">
                                    <b>No news is good news?</b>
                                </div>
                            </div>
                        : !newsLoaded ?
                            <div className="col-12 text-center">
                                <i className="fa-duotone fa-spinner-third fa-spin loading-div"></i>
                            </div>
                        : <></>
                        }
                    </div>
                </SmartContainer>
            </div>

        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(News);

