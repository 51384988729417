import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import SmartContainer from '../layout/SmartContainer';
import { Link } from 'react-router-dom';
import clear from '../assets/img/clear-5x7.png';
import moment from 'moment/moment';

const Faqs = ( props:any ) => {
    const { } = props;

    const init: any = [];
    const [faqsLoaded, setFaqsLoaded] = useState(false);
    const [faqs, setFaqs] = useState(init);

    useEffect(() => {
        (async () => {
            if (!faqsLoaded) {
                const headers = { 'headers': { 'Authorization': 'A5O4l1iHcEqLvb3ilvdm/A==' } }
                let news: any = await axios.get('/api/v2/objects/data/Faqs?size=100&i=0&t=&sort=desc&q=', headers);
                if (news && news.data && news.data.__a && news.data.__a.count > 0) {
                    news.data.r.sort((a:any, b:any) => {
                        let da:any = new Date(a.PublishDate),
                            db:any = new Date(b.PublishDate);
                        return db - da;
                    });
                    setFaqs(news.data.r);
                }
                setFaqsLoaded(true);
            }
        })();
    }, [faqsLoaded])

    return (
        <div>
            <div id="splash-sub" className="small about">
                <SmartContainer className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">FAQs</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="news-div">
                <SmartContainer>
                    <div className="row">
                        {faqs.length && faqsLoaded ?
                            faqs.map((faq: any) =>
                                <div className="col-12" key={faq.__i.guid}>
                                    <div className="news-item">
                                        <h5>{faq.Question}</h5>
                                        <p>{faq.Answer}</p>
                                    </div>
                                </div>
                            )
                            : !faqs.length && faqsLoaded ?
                                <div className="col-12">
                                    <div className="alert alert-warning">
                                        <b>No FAQs to display.</b>
                                    </div>
                                </div>
                                : !faqsLoaded ?
                                    <div className="col-12 text-center">
                                        <i className="fa-duotone fa-spinner-third fa-spin loading-div"></i>
                                    </div>
                                    : <></>
                        }
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(Faqs);

