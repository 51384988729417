import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import SmartContainer from '../layout/SmartContainer';

const ContactUs = (props: any) => {
    const {} = props;

    return (
        <div>
            <div id="splash-sub" className="small about">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
                <SmartContainer>
                    <div id="contact-form">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 style={{ marginBottom: '30px' }}><span className="k">Contact knowtifi</span></h2>
                                <p>We are committed to putting safety in the palm of your hand. How can we help you today?</p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="form-wrap">
                                <div className="input-group form-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user fa-fw" aria-hidden="true"></i>
                                    </span>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" required={true}/>
                                </div>
                                <div className="input-group form-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-envelope fa-fw" aria-hidden="true"></i>
                                    </span>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" required={true}/>
                                </div>
                                <div className="input-group form-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-phone fa-fw" aria-hidden="true"></i>
                                    </span>
                                    <input type="phone" className="form-control" id="phone" name="phone" placeholder="Phone Number"/>
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" id="message" name="message" placeholder="How can we help you?" required={true}/>
                                </div>

                                <div className="form-group">
                                    <div>
                                        <span id="submit-button">
                                            <button className="btn" id="submitContact">
                                                <i className="fa fa-send" aria-hidden="true"></i> &nbsp;Send Your Message
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(ContactUs);

