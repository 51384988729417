import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import phoneframe from '../assets/img/phone-frame.png';
import SmartContainer from '../layout/SmartContainer';

import happyFamily from '../assets/img/istockphoto-666604098-2048x2048.jpg';
import flashing from '../assets/img/exterior-flashing-lights.gif';
import { Link } from 'react-router-dom';

const Home = (props: any) => {
    const {} = props;

    return (
        <>
            <div id="splash">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div id="splash-title-small">
                                <h1>Introducing knowtifi</h1>
                                <h2><b>Safety</b> in the palm of your hands.</h2>
                            </div>
                            <div id="splash-content">
                                <div id="splash-phone">
                                    <img className="phoneframe" src={phoneframe}/>
                                </div>
                                <div id="splash-text">
                                    <div id="splash-title-large">
                                        <h1>Introducing knowtifi</h1>
                                        <h2><b>Safety</b> in the palm of your hand</h2>
                                    </div>
                                    <div className="list-header">Help is just a click away:</div>
                                    <ul>
                                        <li>Dial 911 or text chat with emergency personnel</li>
                                        <li>Instantly relay GPS location data</li>
                                        <li>Alert your friends and family</li>
                                        <li>Activate flashing lights at your home</li>
                                    </ul>
                                    <p>...all with the push of a button!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="bg-gray section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h2 style={{marginBottom:'30px'}}><span className="k">Inform &amp; Be Informed</span><br/>Introducing the knowtifi Mobile App</h2>
                            <div className="text-up">
                                <p>Everyone knows when an emergency happens, seconds matter. The faster you get help,
                                    the better.</p>
                                <p>Now, with the knowtifi app, you no longer have to rely on yesterday’s technology,
                                    which can delay 911 response. That delay often means help arrives too late.</p>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-6">
                            <img src={happyFamily} className="img-fluid" />
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
            <SmartContainer>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{marginBottom:'30px'}}><span className="k">Know Now</span><br/>Your Family's Safety Is in Your Hand</h2>
                        <div className="columns-2">
                            <p>If a loved one needs help, would you want to know immediately or hours later? With the knowtifi app, you can know now – at the same time 911 is notified.</p>
                            <p>Protect your family with the one-touch, easy-to-use knowtifi app. With it, 911 is immediately alerted and emergency crews are dispatched, and your trusted contacts are informed at the same time the app is activated.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h1 className="hows-it-work">How Does <span className="k">knowtifi</span> Work?</h1>
                        <p className="hows-it-work-sub">Using the knowtifi app is simple. It's designed that way.<br/>After all, in an emergency, you or those you care about need to get help fast.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 text-center hows-it-work-item">
                        <img src="https://via.placeholder.com/180" />
                        <h3>1. Make the call</h3>
                        <p>Open the knowtifi app and tap the 911 icon to initiate a call to 911 or to start a chat session with knowtifi dispatchers.</p>
                    </div>
                    <div className="col-12 col-md-4 text-center hows-it-work-item">
                        <img src="https://via.placeholder.com/180" />
                        <h3>2. Alert loved ones</h3>
                        <p>Your emergency contacts are immediately notified with an app notification or text massage on their phones.</p>
                    </div>
                    <div className="col-12 col-md-4 text-center hows-it-work-item">
                        <img src="https://via.placeholder.com/180" />
                        <h3>3. Share smart</h3>
                        <p>Your GPS location is shared with 911 and your emergency contacts. 911 responds and first responders dispatched.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="top-border" style={{marginBottom:'30px'}}><span className="k">911Switch</span> Lights the Way to Safety</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="text-up">
                            <p>In addition to your trusted contacts being notified by the knowtifi app, adding a 911Switch – a rapid alert light – helps alert emergency crews and others who can render aid. In an emergency, visibility is crucial for first responders.</p>
                            <p><Link to={'/911switch'}>Learn more about the 911Switch – Powered by knowtifi</Link>
                                &nbsp; &nbsp;<i className="fa-sharp fa-solid fa-caret-right"></i><i className="fa-sharp fa-solid fa-caret-right"></i></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src={flashing} className="img-fluid" />
                    </div>
                </div>
            </SmartContainer>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Home);

